import { useEffect, useRef, useState } from 'react';

import GQLClient from '../graphql/utils';

import type { WORDPRESS_QUERY } from '../graphql/enums';

interface QueryOptions<TData, TVariables> {
  variables?: TVariables;
  onCompleted?: (data?: TData) => void;
  onError?: (error?: string) => void;
  /**
   * The query won't launch while this condition returns true.
   */
  skip?: boolean;
}

interface QueryResult<TData> {
  data?: TData;
  error?: string;
  loading: boolean;
}

const currentRequests = new Map<string, number>();

export function useQuery<
  TData extends object = any,
  TVariables extends object = any,
>(
  query: WORDPRESS_QUERY,
  options?: QueryOptions<TData, TVariables>,
): QueryResult<TData> {
  const [queryResult, setQueryResult] = useState<QueryResult<TData>>({
    loading: options?.skip ? false : true,
    data: undefined,
    error: undefined,
  });

  const isMounted = useRef(true);

  useEffect(() => {
    if (options?.skip) return;

    isMounted.current = true;
    const requestKey = JSON.stringify({
      query,
      variables: options?.variables || null,
    });

    if (currentRequests.has(requestKey)) return;

    setQueryResult({ loading: true, data: undefined, error: undefined });

    currentRequests.set(requestKey, Date.now());

    GQLClient.query({ query, variables: options?.variables })
      .then((response) => {
        currentRequests.delete(requestKey);

        const { data, error } = response;

        if (error && options?.onError) {
          options?.onError(error);
        }

        if (isMounted.current) {
          setQueryResult({ loading: false, data, error });
        }

        if (!error && options?.onCompleted) {
          options?.onCompleted(data);
        }
      })
      .catch((reason) => {
        currentRequests.delete(requestKey);

        if (reason && options?.onError) {
          options?.onError(reason);
        }

        if (isMounted.current) {
          setQueryResult({
            loading: false,
            data: undefined,
            error: reason.message,
          });
        }
      });

    return () => {
      isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, options?.skip, JSON.stringify(options?.variables)]);

  return queryResult;
}
