import { WORDPRESS_QUERY } from '../graphql/enums';
import { useQuery } from './useQuery';

import type { CouponUsesLeft, EmployeeSnackbar } from '../graphql/querys/index';

export const useEmployeeSnackbar = ({ id }: { id: string }) => {
  const {
    loading: snackbarLoading,
    error: snackbarError,
    data: snackbarData,
  } = useQuery<EmployeeSnackbar>(WORDPRESS_QUERY.GET_EMPLOYEE_SNACKBAR);

  const {
    loading: couponUsesLeftLoading,
    error: couponUsesLeftError,
    data: couponUsesLeftData,
  } = useQuery<CouponUsesLeft>(WORDPRESS_QUERY.GET_EMPOYEE_CUPON_LEFT_USES, {
    variables: {
      userId: id,
      coupon:
        snackbarData?.bcGeneralSettings.ajustesGenerales.employeeSnackbar
          .coupon,
    },
    skip:
      !snackbarData ||
      !snackbarData.bcGeneralSettings.ajustesGenerales.employeeSnackbarActive,
  });

  return {
    snackbarLoading,
    snackbarError,
    snackbarData,
    couponUsesLeftLoading,
    couponUsesLeftError,
    couponUsesLeftData,
  };
};
